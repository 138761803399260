import { NavLink as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container, Typography } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { useState } from 'react';
import { PATH_AUTH } from 'src/routes/paths';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const [visitor,setVisitor] =useState('customer')

  const navigate=useNavigate()
 const {id}=useParams()
  const handleVisitor=(num)=> {
    if(num ==1){
      setVisitor("customer")
      navigate('/')

    }else if(num ==2) {
      setVisitor("staff")
      navigate('/staff')
    }else {
      setVisitor('admin')
      navigate('/admin')
    }
  }
  
  return (
    <AppBar color={isHome ? 'transparent' : 'transparent'} sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor:"transparent"
          }}
        >
          <RouterLink to="/" >
            <Logo />
          </RouterLink>
          {/* <Label color="info" sx={{ ml: 1 }}>
            Shop America
          </Label> */}
          {/* <Typography fontSize={"large"}>Shop America</Typography> */}
          <Box sx={{ flexGrow: 1 }} />

          {/* <Box>
            <Typography>Today Exchange rate :$12</Typography>
          </Box> */}

          {/* <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden> */}

          {/* <Button variant="contained" target="_blank" href="https://material-ui.com/store/items/minimal-dashboard/">
            Purchase Now
          </Button> */}
        {/* <Box  sx={{width:"800px",display:{xs:"none",sm:"flex"}, justifyContent:"end"}}>
        <Button sx={{marginRight:"20px", backgroundColor: visitor === 'customer' ? 'white' : '',
            color: visitor === 'customer' ? '#0ef03f' : '','&:hover': {
              backgroundColor: visitor === 'customer' ? 'white' : '',
              color: visitor === 'customer' ? '#0ef03f' : '',
            },boxShadow:"0"}} variant="contained" target="_blank" 
            onClick={()=>handleVisitor(1)}
            >
            Customer Login
          </Button>
          <Button
          sx={{
            marginRight: '20px',
            backgroundColor: visitor === 'staff' ? 'white' : 'default',
            color: visitor === 'staff' ? '#ea3140' : 'default',
            '&:hover': {
              backgroundColor: visitor === 'staff' ? 'white' : 'default',
              color: visitor === 'staff' ? '#ea3140' : 'default',
            },
            boxShadow: "0"
          }}
          variant="contained"
          onClick={()=>handleVisitor(2)}
        >
          Staff Login
        </Button>
        <Button
          sx={{
            backgroundColor: visitor === 'admin' ? 'white' : 'default',
            color: visitor === 'admin' ? '#ea3140' : 'default',
            '&:hover': {
              backgroundColor: visitor === 'admin' ? 'white' : 'default',
              color: visitor === 'admin' ? '#ea3140' : 'default',
            },
            boxShadow: "0"
          }}
          variant="contained"
          onClick={()=>handleVisitor(3)}
        >
          Admin Login
        </Button>
        </Box> */}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
