import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import AuthLayout from 'src/layouts/AuthLayout';
import { Box } from '@mui/material';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import axios from 'axios';
import { REST_API } from 'src/DefaultValues';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
    

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    email: Yup.string()
    .email('Email must be a valid email address')
    .test('at-least-one-letter', 'Email must be a valid email address', value => {
      if (!value) return false; // handle empty values
      const beforeAtSymbol = value.split('@')[0]; // get the part before @
      return /[a-zA-Z]/.test(beforeAtSymbol); // check if it contains at least one letter
    })
    .required('Email is required'),
    password: Yup.string().required('Password is required')
  });
            
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      // remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
      
         await login(values.email, values.password)
        // const response = await axios.post(`${REST_API}webservice/customer/login-customer`,{values})
        // console.error(response.data);
      .then(res =>{
        console.log(res)
        enqueueSnackbar('Login success', {variant: 'success'});
      }).catch(err =>{
        console.log(err)
        enqueueSnackbar('Login credentials are not correct', {
          variant: 'error'})
      })



        // if (isMountedRef.current) {
          setSubmitting(false);
          navigate(PATH_DASHBOARD.root)
        // }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik} >
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
           {/* <div>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
           </div> */}

        
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            sx={{backgroundColor:"#fff",borderRadius:"10px"}}
            placeholder='Enter Email'
            // label={
            //   <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"large"}>
            //     Email address
            //   </Typography>
            // }
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          
          />
     
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            sx={{backgroundColor:"#fff",borderRadius:"10px"}}
            placeholder='Enter Password'
            // label={
            //   <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"large"}>
            //     Password
            //   </Typography>
            // }
            
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={
              <Typography variant="body1" fontWeight="bold" color={"#fff"}>Remember me</Typography>
            }
          /> */}

          <Link component={RouterLink} color={"red"} fontSize={"15px"} variant="subtitle2" fontWeight={"bold"} to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack>
        
         <Typography sx={{marginBottom:"5px",fontWeight:"bold",color:"#fff",fontSize:"15px"}}>
         Don’t have an account? &nbsp;
        <Link underline="none" color={"#0ef03f"} fontSize={"20px"} variant="subtitle2" component={RouterLink} to={PATH_AUTH.registerUnprotected}>
          Get started 
        </Link>
         </Typography>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Customer Login 
        </LoadingButton>
      </Form>
      
    </FormikProvider>
  );
}

// #008000