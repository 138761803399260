import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  Button,
  InputAdornment,
  Icon,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
// hooks
// import useAuth from '../../../../hooks/useAuth'
import useAuth from 'src/hooks/useAuth'
// import useIsMountedRef from '../../../../hooks/useIsMountedRef'
import useIsMountedRef from 'src/hooks/useIsMountedRef'

import { UploadAvatar } from 'src/components/upload'
// utils
// import { fData } from '../../../../utils/formatNumber'
import { fData } from 'src/utils/formatNumber'
//
// import countries from '../../../../countries';
// import countries from '../../../../user/countries'
import countries from 'src/components/_dashboard/user/countries'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { REST_API } from 'src/DefaultValues'
import { styled } from '@material-ui/styles'
import { WindowOutlined } from '@material-ui/icons'
import IconButton from 'src/theme/overrides/IconButton'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';


// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))

export default function ResetPasswordDetails({ email  }) {
  const isMountedRef = useIsMountedRef()
  const { enqueueSnackbar } = useSnackbar()
  const { user, updateProfile } = useAuth()
  const [data, setData] = useState([])
  const [showPassword, setShowPassword] = useState(false);


  const navigate = useNavigate()
  

  useEffect(() => {
   
  }, [])

  // const UpdateUserSchema = Yup.object().shape({
  //   password: Yup.string().required('Password is required').min(6, 'Password must be 6 characters or more'),
  //   confirmPassword: Yup.string()
  //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
  //     .required('Confirm password is required'),
  //   // confirmpassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')

  // })

  const UpdateUserSchema = Yup.object().shape({
    password: Yup.string()
    .required('New Password is required').min(6, 'Password must be 6 characters or more'),
    
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // email: data ? data.email : '',
      password:data ? data.pass : '',
      confirmPassword:data ? data.confirmPassword :"",
      // userId:data? data.userId : ''
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log('val---', values)

      try {
        // await updateProfile({ ...values });\
        console.log('values---', values)
        if (formik.isValid) {
      await axios.put(`${REST_API}webservice/customer/reset-password`,{email,values})
      .then(res =>{
        console.log(res.data);
      }).catch(err =>console.log(err))

        if (isMountedRef.current) {
          setSubmitting(false)
        }
      }else {
        enqueueSnackbar("password and confirm password not match", { variant: 'error' });
      }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code })
          setSubmitting(false)
        }
      }
    },
  })

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (file) {
        setFieldValue('photoURL', {
          ...file,
          preview: URL.createObjectURL(file),
        })
      }
    },
    [setFieldValue],
  )

  const handleBack = () => {
    // navigate(PATH_DASHBOARD.staffDetails.root)
    setViewEditPage(false)
  }

  const handleUpdate = async() => {
    if (formik.isValid) {
    console.log('Form submitted', values)

    await axios.put(`${REST_API}webservice/customer/reset-password`,{email,values})
    .then(res =>{
      if(res.data.status===1){
        console.log(res.data);
        enqueueSnackbar('Password Updated Successfully', { variant: 'success' });
        navigate('/')
      }
     

    }).catch(err =>{
      console.log(err)
      enqueueSnackbar('Password not updated', { variant: 'error' });
    })
  }else {
    const errorField = Object.keys(formik.errors)[0]; // Get the first error field
    const errorMessage = formik.errors[errorField];
    enqueueSnackbar(errorMessage, { variant: 'error' });
  }
  }

 
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Typography variant="h3" paragraph>
                Reset your password?
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the new password associated with your account and enjoy your shopping with Shop America From Nigeria
              </Typography>
          <Grid item xs={12} md={12}>
              <Stack spacing={{ xs: 2, md: 3 }}>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Box sx={{width:"100%"}}>
                <TextField
                    fullWidth    
                    label="Enter New Password"
                    value={data ? data.password : ''}
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Box>

                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Box sx={{width:"100%"}}>
                <TextField
                    fullWidth    
                    label="Confirm Password"
                    // value={data ? data.password : ''}
                    {...getFieldProps('confirmPassword')}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                  />
                </Box>

                </Stack>
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end',flexDirection:'column' }}>
                {/* <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting} sx={{mr:3}}>
                  Update
                </LoadingButton> */}
                <Button size="large" fullWidth variant='contained' onClick={handleUpdate}>Update Password</Button>
                <Button fullWidth size="large" sx={{ mt: 1 }} onClick={()=>navigate('/')}>
                Back
              </Button>
              </Box>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
