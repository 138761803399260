import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Input,
  TextField,
  InputLabel, Select, MenuItem, Typography, Grid, Container
} from '@material-ui/core';
// utils
// import { fCurrency } from '../../../utils/formatNumber';
import { fCurrency } from '../../../../utils/formatNumber';
//
// import Label from '../../Label';
// import Scrollbar from '../../Scrollbar';
// import MoreMenuButton from '../../MoreMenuButton';
import Scrollbar from 'src/components/Scrollbar';
import Label from 'src/components/Label';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import DualShok from '../../../../assets/img/dualShock.png'
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ProductTimeline from './ProductTimeLine';
import { MIconButton } from '../../../@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import StatusDescription from './StatusDescription';
import { useEffect, useState } from 'react';
import Modal from '../../project-modal/ProjectModel';
import axios from 'axios';
import { REST_API } from 'src/DefaultValues';

// ----------------------------------------------------------------------

export default function HomeProductDetails() {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [agreement,setAgreement] = useState(false)
  const [agree,setAgree]=useState(false)
  
 
  const handleSave=()=> {
    enqueueSnackbar('Item Saved', {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }
  const handleSubmit=()=> {
    setAgreement(!agreement)
  }
  const handleAgree=()=>{
    setAgree(true)
  }
  return (
    <Card sx={{p:3}}>
      {/* <CardHeader title="Item Details" sx={{ mb: 3 }} /> */}
      <Modal
      open={agreement}
      handleClose={()=>{
        setAgreement(false)
      }}
      modalTitle={agree ? "Order Placed" :'Service Agreements'}>
        <>
        <Divider/>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",mt:3}}> 
          <Typography color={agree ?"green":""}>{agree ? "Your order has been submitted and being reviewed you shall get an invoice shortly" :"Service Agreement ..."}</Typography>
        </Box>
        <Box sx={{display:"flex",justifyContent:"end",mt:3}}>
          {
            agree ? "" :
            (
            <>
               <Button variant='contained' sx={{mr:3}} onClick={handleSubmit}>No, i do not Agree</Button>
               <Button variant='contained' onClick={handleAgree}>Yes, i Agree</Button>
               </> )
          }
        </Box>
        </>
      </Modal>
     <Box sx={{width:"100%",display:"flex",justifyContent:"end"}}>
        {/* <Typography>Item Details</Typography> */}
        {/* <Button sx={{color:"red",border:"1px solid",borderColor:"#32b59f", mt:{xs:10 ,sm:0},width:{xs:"100%",sm:"50%"}}}>
        <Typography>Transaction Status:Awaiting Approval</Typography>
        </Button> */}
        <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.orderHistory.root}>
        <Button align='right' variant='contained' sx={{backgroundColor:"#32b59f"}}>Back</Button>
        {/* <Typography align='right'>Back</Typography> */}
        </Link>
     </Box>
      {/* <Divider sx={{backgroundColor: '#32b59f',mt:2}} /> */}
      {/* <Box sx={{ width: "100%"}}>
      <Scrollbar>
      <Grid container spacing={2}>
    
        <Grid item xs={12} sm={2} sx={{mt:3}}>
          <img src={DualShok} width={"100%"} alt='img' />
        </Grid>
       
        <Grid item xs={12} sm={10} container direction="column"  >
         
          <Grid item sx={{marginLeft:"10px",marginTop:"20px",display:"flex"}}> 
          <Box>
          <Typography fontSize={"small"}>Item Name</Typography>
          <Typography fontSize={"small"}>DualShock 4 wireless controller for payStation 4-Fortnite jet lack</Typography>
          </Box>
          <Box sx={{ml:8}}>
          <Typography fontSize={"small"}>Customer Name</Typography>
          <Typography fontSize={"small"}>Felix chizzoba</Typography>
          </Box>
          <Box sx={{ml:5}}>
          <Typography fontSize={"small"}>Phone</Typography>
          <Typography fontSize={"small"}>234 9965432</Typography>
          </Box>
          <Box sx={{ml:3}}>
          <Typography fontSize={"small"}>Email</Typography>
          <Typography fontSize={"small"}>customer1@gmail.com</Typography>
          </Box>
          </Grid>
           
         
          <Grid item  sx={{marginLeft:"10px",marginTop:"10px",display:"flex"}}> 
          <Box>
          <Typography fontSize={"small"}>Cost of item</Typography>
          <Typography fontSize={"small"}>$ 50</Typography>
          </Box>
          <Box sx={{ml:6}}>
          <Typography fontSize={"small"}>Order no</Typography>
          <Typography fontSize={"small"}>000012</Typography>
          </Box>
          <Box sx={{ml:6}}>
          <Typography fontSize={"small"}>Supplier Order no</Typography>
          <Typography fontSize={"small"} align='center'>----</Typography>
          </Box>

          <Box sx={{ml:8}}>
          <Typography fontSize={"small"}>Address</Typography>
          <Typography fontSize={"small"} >Mr Daniel Izuchukwu Nwoye. 8, My Street, Ilassan Lekki, Lagos 105102.</Typography>
          </Box>
           </Grid>
           
        </Grid>
      </Grid>
      </Scrollbar>
    </Box> */}
      <Scrollbar>
        <Box sx={{mt:5}}>
        <StatusDescription retrive={true}/>

        {/* <Box sx={{display:"flex",alignItems:"center",justifyContent:"end",mt:2}}>
                 <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.root}>
                 <Button variant='contained'sx={{mr:3,backgroundColor:"#32b59f"}} >Close</Button>
                 </Link>
                 
                 <Button variant='contained' sx={{backgroundColor:"#32b59f",mr:3}} >Save</Button>
                 <Button variant='contained' sx={{backgroundColor:"#32b59f"}} onClick={handleSubmit}>Submit</Button>
                  
                 </Box> */}
        </Box>
      </Scrollbar>

              {/* <Box sx={{display:{xs:"flex",md:"none",justifyContent:"center",marginTop:"20px"}}}>
                 <ProductTimeline />              
              </Box>              */}

      {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box> */}
    </Card>
  );
}
