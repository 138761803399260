import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Input,
  TextField,
  InputLabel, Select, MenuItem,InputAdornment,
  Tooltip,
  CircularProgress,
  Grid
} from '@material-ui/core';
// utils
// import { fCurrency } from '../../../utils/formatNumber';
import { fCurrency } from '../../../../utils/formatNumber';
//
// import Label from '../../Label';
// import Scrollbar from '../../Scrollbar';
// import MoreMenuButton from '../../MoreMenuButton';
import Scrollbar from 'src/components/Scrollbar';
import Label from 'src/components/Label';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { MIconButton, MLinearProgress } from 'src/components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import {Typography} from '@material-ui/core';
import {Autocomplete} from '@material-ui/core';
import { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios'
import { REST_API } from 'src/DefaultValues';
import LoadingScreen from 'src/components/LoadingScreen';
import { userId } from 'src/contexts/JWTContext';

// ----------------------------------------------------------------------



const randomDate = faker.date.past(); 
const INVOICES = [
  {
    id: faker.datatype.uuid(),
    itemName: 'DualShock 4 wireless controller for payStation 4-Fortnite jet lack',
    date:"01/01/2024",
    price: faker.finance.amount(),
    status: 'in_progress',
    type:"physical",
    suplier:"Shein",
    customerName:"customer1"


  },
  {
    id: faker.datatype.uuid(),
    itemName: 'Casual Shirt',
    date:"01/01/2024",
    price: faker.finance.amount(),
    status: 'paid',
    type:"physical",
    suplier:"Amazon",
    customerName:"customer2"
  },
  {
    id: faker.datatype.uuid(),
    itemName: 'Digital Book.North American Coins',
    date:"01/01/2024",
    price: faker.finance.amount(),
    status: 'out_of_date',
    type:"physical",
    type:"physical",
    suplier:"Flipkart",
    customerName:"customer3"


  }
];

// ----------------------------------------------------------------------

export default function TrackOrders() {

      // visitor status
  const visitor="customer"
  
  const [view,setView]=useState(true)
  const [orderNumber,setOrderNumber] = useState()
  const [viewData,setViewData]=useState([])
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()
 

  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleInvoice=()=> {
    enqueueSnackbar('invoice currently not available', {
      variant: 'warning',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }
  const top100Films = [
    { Pickup: 'Awaiting Payment'},
    { Pickup: 'Order Placed' },
    { Pickup: 'Ready for Shipment' },
    { Pickup: 'Undergoing Clearence' },
    { Pickup: 'Ready for Pickup' },
    { Pickup: 'Marked for Demurrage' },
    { Pickup: 'Marked for Auction' },
  ];

  const [updateStatus,setUpdateStatus] = useState(false)

  const changeStatus=(id) => {
     setUpdateStatusMap((prevMap) => ({
      ...prevMap,
      [id]: !prevMap[id]
    }));
  }

  const handleTrack=()=>{

if(orderNumber){
  navigate(`${PATH_DASHBOARD.user.PurchaseProduct}/${userId}/${orderNumber}`)

}else{
  enqueueSnackbar("Please Enter a valid Order Number that starts with 'ORD-'", { variant: 'error' });
}
  }
//   const handleTrack=()=>{
//     // setIsLoading(true);
//     // setView(!view)
//     // axios.post(`${REST_API}webservice/customer/find-track-by-order_id`,{orderNumber,customerId:userId})
//     // .then(res =>{
//     //     console.log("setViewData-------------",res.data.result)
//     //     setViewData(res.data.result)
      
//     // }).catch(err =>console.log(err))
//     // .finally(() => {
//     //   setTimeout(() => {
//     //     setIsLoading(false);
//     //   }, 2000); 
//     // });
// if(orderNumber){
//   navigate(`${PATH_DASHBOARD.user.PurchaseProduct}/${userId}/${orderNumber}`)

// }else{
  
//   enqueueSnackbar("Please Enter a valid Order Number",{variant:'error'})
// }
    

//   }

  // const handleGetNumber =(e)=>{
  //   console.log("+++++",e.target.value);
  //   setOrderNumber(e.target.value)
  // }


  const handleGetNumber = (e) => {
    let value = e.target.value.trim();
  
    // Convert to uppercase to make the check case-insensitive
    value = value.toUpperCase();
  
    // Regular expression to match "ORD-" followed by exactly one digit
    // const validPattern = /^ORD-(\d)$/;
    const validPattern = /^ORD-(\d+)$/;

    // Check if the value matches the pattern
    const match = value.match(validPattern);
  
    if (match) {
      const orderNumber = match[1]; // Extract the digit part
      console.log("Valid order number:", orderNumber);
      setOrderNumber(orderNumber); // Set only the number part
    } else {
      console.log("Invalid format: The order number must be in the format 'ORD-' followed by exactly one digit.");
      setOrderNumber(''); // Clear the order number if the format is invalid
    }
  };
  
//   const handleGetNumber = (e) => {
//     const value = e.target.value;
//     console.log("+++++", value);
//     const parts = value.split("-");
//     if (parts.length > 1) {
//         const afterHyphen = parts[1];
//         console.log("Value after hyphen:", afterHyphen);
//     setOrderNumber(afterHyphen);

//     } else {
//         console.log("No hyphen found in the value.");
//     }
// }
  
  
  
  
  


if (isLoading) {
  return(
      <>
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height:"100vh" }}>
     <LoadingScreen/>
    </Box>
  </>
  ) 
}


  return (
    <Card sx={{height:"50vh"}}>
       <CardHeader title="Track Your Orders" sx={{ mb: 3 }} />
       <Box sx={{p:3,display:"flex",justifyContent:"space-between"}}>
     {/* <Typography>Track Your Order</Typography> */}
     {
          view ? "":
     <Button variant='contained' onClick={()=>setView(!view)}>Back</Button>
     }
       </Box>
     <Divider sx={{mt:2}}/>

     {
      view ? (

     
       <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Box sx={{width:"500px",mt:3}}>
            <Typography>Order Number</Typography>
            <TextField
             variant="outlined"
             placeholder="Enter Order Number eg: ORD-xx"
              onChange={(e)=>handleGetNumber(e)}
             sx={{width:"70%",backgroundColor:"whitesmoke"}}
//   InputProps={{
//     endAdornment: (
//       <InputAdornment position="end">
//         <SearchIcon color="action" />
//       </InputAdornment>
//     )
//   }}
/>
<Button sx={{backgroundColor:"#32b59f",color:"#fff",height:"50px",width:"20%",ml:2, '&:hover': {
      backgroundColor: "#32b59f", // Keep the same background color
    },}} onClick={handleTrack}>Track</Button>
        </Box>
       </Box>
         ) :
            <Scrollbar>
              <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order No</TableCell>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Type of item</TableCell>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               { viewData.length === 0  ? 
           
               <TableCell colSpan={8}>
               No Product Available With This Order Number
             </TableCell>
          
             :
              <TableRow key={viewData.id}>
              <TableCell align='left'>INV-{viewData.id}</TableCell>
              <Tooltip title={viewData.item_name}>

                  <TableCell  align='left' sx={{fontSize:"small"}} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap' }}>{viewData.item_name}</TableCell>
                  </Tooltip>

                  <TableCell  align='left' style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{viewData.date}</TableCell>
                  
                  <TableCell  align='left' style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
  {viewData.status && (
    <Label
      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
      color={
        (viewData.status === 'Awaiting Approval' && 'warning') ||
        (viewData.status === 'Approved for Payment' && 'success') ||
        (viewData.status === 'Disapprove' && 'error') ||
        (viewData.status === 'Order Placed' && 'success') ||
        (viewData.status === 'Saved' && 'error') ||
        'primary'
      }
    >
      {sentenceCase(viewData.status)}
    </Label>
  )}
</TableCell>
<TableCell  align='left' style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
  {viewData.type_of_item}</TableCell>

  <Tooltip title={viewData.supplier_name}>

<TableCell  align='left' style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{viewData.supplier_name}</TableCell>

</Tooltip>

<TableCell  align='right' style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{viewData.total}</TableCell>

<TableCell sx={{display:"flex"}}>
                       <Button variant='contained' sx={{ marginRight: "10px", backgroundColor: "#32b59f" }}
                       onClick={()=>navigate(`${PATH_DASHBOARD.user.productDetail}/${viewData.id}`)}>View</Button>

                       </TableCell>
 
              </TableRow>
}
              </TableBody>
              </Table>
              </TableContainer>
            </Scrollbar>


        }
    </Card>
  );
}
