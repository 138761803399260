import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel, Select, MenuItem
} from '@material-ui/core';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
//
import Label from '../../../Label';
import Scrollbar from '../../../Scrollbar';
import MoreMenuButton from '../../../MoreMenuButton';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';

// ----------------------------------------------------------------------

// const INVOICES = [
//   {
//     id: faker.datatype.uuid(),
//     category: 'Android',
//     price: faker.finance.amount(),
//     status: 'in_progress'
//   },
//   {
//     id: faker.datatype.uuid(),
//     category: 'Windows',
//     price: faker.finance.amount(),
//     status: 'paid'
//   },
//   {
//     id: faker.datatype.uuid(),
//     category: 'Mac',
//     price: faker.finance.amount(),
//     status: 'out_of_date'
//   },
//   {
//     id: faker.datatype.uuid(),
//     category: 'Windows',
//     price: faker.finance.amount(),
//     status: 'paid'
//   },
//   {
//     id: faker.datatype.uuid(),
//     category: 'Windows',
//     price: faker.finance.amount(),
//     status: 'in_progress'
//   }
// ];

const randomDate = faker.date.past(); 
const INVOICES = [
  {
    id: faker.datatype.uuid(),
    itemName: 'DualShock 4 wireless controller for payStation 4-Fortnite jet lack',
    date:"01/01/2024",
    price: faker.finance.amount(),
    status: 'in_progress',
    type:"physical",
    suplier:"Shein"

  },

];

// ----------------------------------------------------------------------

export default function Customers() {
  const theme = useTheme();

  return (
    <Card>
      <CardHeader title="Home" sx={{ mb: 1 }} />
      <Box sx={{width:"100%",p:1,display:"flex",alignItems:"center",justifyContent:"space-around"}} >
      <InputLabel id="dropdown-label"    sx={{ color: 'black',marginLeft:"10px" }}>Paste Item Link</InputLabel>
      <TextField variant="outlined" placeholder='http://www.flipkart.com//product' sx={{ width: "70%" }}/>
      <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.homeProductDetails}>
        <Button variant='contained'>Retrive item</Button>
        </Link>
      </Box>
      <Box sx={{width:"100%",p:3}}>
        <Box>
          <TextField variant="outlined" placeholder='Search ...'/>
        </Box>
        <Box sx={{display:{xs:"block",sm:"flex"}, width:"100%",justifyContent:"center",flexWrap:"wrap",mt:3}}>
          <Box>
          <InputLabel id="dropdown-label"   sx={{ color: 'black' }}>Select Status</InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
       sx={{width:"230px",height:"40px",mr:5}}
       placeholder='select Status'
       label="3" 
      >
        <MenuItem value="option1">Option 1</MenuItem>
        <MenuItem value="option2">Option 2</MenuItem>
        <MenuItem value="option3">Option 3</MenuItem>
      </Select>
      </Box>
      <Box>
      <InputLabel id="dropdown-label"   sx={{ color: 'black' }}>Select type of item</InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
       sx={{width:"250px",height:"40px"}}
       placeholder='Select type of item'
       label="3" 
     
      >
        <MenuItem value="option1">Option 1</MenuItem>
        <MenuItem value="option2">Option 2</MenuItem>
        <MenuItem value="option3">Option 3</MenuItem>
      </Select>
      </Box>
      <Box sx={{ml:5}}>
      <InputLabel id="dropdown-label"   sx={{ color: 'black' }}>Select Suplier</InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
       sx={{width:"230px",height:"40px"}}
       placeholder='select Supplier'
       label="3" 
      >
        <MenuItem value="option1">Option 1</MenuItem>
        <MenuItem value="option2">Option 2</MenuItem>
        <MenuItem value="option3">Option 3</MenuItem>
      </Select>
      </Box>
        </Box>
      </Box>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead >
              <TableRow >
                <TableCell >Order No</TableCell>
                <TableCell>Item Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Type of item</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell> </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {INVOICES.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{`INV-${row.id}`}</TableCell>
                  <TableCell>{row.itemName}</TableCell>
                  <TableCell>{row.date}</TableCell>
                    <TableCell>{row.price}</TableCell>
                  <TableCell>
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={
                        (row.status === 'in_progress' && 'warning') ||
                        (row.status === 'out_of_date' && 'error') ||
                        'success'
                      }
                    >
                      {sentenceCase(row.status)}
                    </Label>
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.suplier}</TableCell>
                  <TableCell align="right">
                    {/* <MoreMenuButton /> */}
                   <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.product}>
                    <Button variant='contained'>Details</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box>
    </Card>
  );
}
