
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment, 
  Divider,
  Button,
  Link,
  CircularProgress,
  Paper,
  FormHelperText
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import { REST_API } from 'src/DefaultValues';
import { userId } from 'src/contexts/JWTContext';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { styled } from '@material-ui/styles';
import { UploadSingleFile } from '../../../upload';
import PropTypes from 'prop-types';
import Modal from '../../project-modal/ProjectModel';
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import { useDropzone } from 'react-dropzone';
import { MLinearProgress } from 'src/components/@material-extend';
import LoadingScreen from 'src/components/LoadingScreen';

const top100Films = [
    { Pickup: 'Abuja', Supplier: 'Us' ,type:"physical"},
    { Pickup: 'Lagos', Supplier: "South Africa",type:"Digital" }
];

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
}));

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5, 0),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    '&:hover': {
        opacity: 0.72,
        cursor: 'pointer'
    },
    [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));
const style = {
    p: 2,
    minHeight: 160,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { m: 1 }
  };

StatusDescription.propTypes = {
    progress: PropTypes.number,
  buffer: PropTypes.number,
    error: PropTypes.bool,
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    sx: PropTypes.object
};

export default function StatusDescription({progress,buffer, retrive, error, file, sx, ...other }) {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple: false,
        ...other
    });
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const { user, updateProfile } = useAuth();
    const [type, setType] = useState(false);
    const [unitCost, setUnitCost] = useState('');
    const [quantity, setQuantity] = useState('');
    const [amount, setAmount] = useState('');
    const [length, setLength] = useState('');
    const [bredth, setBredth] = useState('');
    const [height, setHeight] = useState('');
    const [total, setTotal] = useState('');
    const [agreement, setAgreement] = useState(false);
    const [agree, setAgree] = useState(false);
    const [supCountries, setSupCountries] = useState([]);
    const [pickupLocations, setPickupLocations] = useState([]);
    const [forwarderFees, setForwarderFees] = useState([]);
    const [totalForwarderFee, setTotalForwarderFee] = useState();
    const [subTotal, setSubTotal] = useState(0);
    const [serviceChargeData, setServiceChargeData] = useState([]);
    const [totalServiceFee, setTotalServiceFee] = useState();
    const [pickupLocationFee, setPickupLocationFee] = useState();
    const [vatData, setVatData] = useState([]);
    const [vatRate, setVatRate] = useState(0);
    const [save, setSave] = useState(2);
    const [viewData, setViewData] = useState([]);
    const [selectPrefferedLocation, setSelectPrefferedLocation] = useState([]);
    const [selectSuplierCountry, setSelectSuplierCountry] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const state = useLocation();
    const link = state.state;
    const [productLink, setProductLink] = useState('');
    const [salesTax, setSalesTax] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [productStatus, setProductStatus] = useState('Saved');
    const [selectedFile, setSelectedFile] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state variable to track form submission
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get(`${REST_API}webservice/admin/get-product/${id}`)
            .then(res => {
                setViewData(res.data.result);
            }).catch(err=>console.log("err",err))
            .finally(() => {
                setTimeout(() => {
                  setIsLoading(false);
                }, 2000); 
              });
    }, [id]);

    useEffect(() => {
        axios.get(`${REST_API}webservice/admin/get-country`)
            .then(res => {
                if (res.data.status === 1) {
                    setSupCountries(res.data.data);
                }
            }).catch(err => console.log(err));

        axios.get(`${REST_API}webservice/admin/getAll-pickup-location`)
            .then((res) => {
                if (res.data.status === 1) {
                    setPickupLocations(res.data.data);
                }
            })
            .catch((err) => console.log(err));

        axios.get(`${REST_API}webservice/admin/getAll-forwarder-fee`)
            .then((res) => {
                if (res.data.status === 1) {
                    setForwarderFees(res.data.data);
                }
            })
            .catch((err) => console.log(err));

        axios.get(`${REST_API}webservice/admin/getAll-service-fee`)
            .then(res => {
                if (res.data.status === 1) {
                    setServiceChargeData(res.data.data);
                }
            }).catch(err => console.log(err));

        axios.get(`${REST_API}webservice/admin/getAll-vat`)
            .then(res => {
                if (res.data.status === 1) {
                    setVatData(res.data.data);
                }
            });

    }, []);


    


    const handleLength = (e) => {
        const L = e.target.value;
        setLength(L);
        calculateTotal(L, bredth, height);
        setFieldValue('lInches', L);
    };

    const handleBredth = (e) => {
        const B = e.target.value;
        setBredth(B);
        calculateTotal(length, B, height);
        setFieldValue('bInches', B);
    };

    const handleHeight = (e) => {
        const H = e.target.value;
        setHeight(H);
        calculateTotal(length, bredth, H);
        setFieldValue('hInches', H);
    };

    const calculateTotal = (L, B, H) => {
        const LBH = L * B * H;
        setTotal(LBH);
        setFieldValue('weight', LBH);
    };

    const handleUnitCostChange = (e) => {
        const cost = e.target.value;
        setUnitCost(cost);
        calculateAmount(cost, quantity);
        setFieldValue('unitCost', cost);
    };

    const handleQuantityChange = (e) => {
        const qty = e.target.value;
        setQuantity(qty);
        calculateAmount(unitCost, qty);
        setFieldValue('quantity', qty);
        setFieldValue('ForwarderFee', totalForwarderFee);
    };

    const calculateAmount = (cost, qty) => {
        const total = qty ? cost * qty : cost;
        setAmount(total);
        setFieldValue('amount', total);
        setSubTotal((total));
        setFieldValue('subtotal', subTotal);
    };

    useEffect(() => {
        const totalAmount = subTotal;
        let foundFee = false;

        const weight = values.actualWeight ? values.actualWeight : 0;
        const volumetricWeight = (values.lInches * values.bInches * values.hInches / 166).toFixed(2);
        const theCountry = values.suplierCountry?.country;
        let volumetricWeightTotal = 0;
        let actualWeightTotal = 0;

        forwarderFees.forEach((feeObj) => {
            if (volumetricWeight) {
                if (theCountry === feeObj.country_name && feeObj.weightType === "Volumetric Weight") {
                    if (!feeObj.setWeightTo) {
                        if (volumetricWeight > feeObj.setWeightFrom) {
                            let rate = volumetricWeight * feeObj.fee;
                            let fixedFee = feeObj.fixedFee;
                            volumetricWeightTotal = fixedFee > rate ? fixedFee : rate;
                        }
                    } else {
                        if (volumetricWeight >= feeObj.setWeightFrom && volumetricWeight <= feeObj.setWeightTo) {
                            let rate = volumetricWeight * feeObj.fee;
                            let fixedFee = feeObj.fixedFee;
                            volumetricWeightTotal = fixedFee > rate ? fixedFee : rate;
                        }
                    }
                }
                if (theCountry === feeObj.country_name && feeObj.weightType === "Actual Weight") {
                    if (!feeObj.setWeightTo) {
                        if (weight > feeObj.setWeightFrom) {
                            let rate = weight * feeObj.fee;
                            let fixedFee = feeObj.fixedFee;
                            actualWeightTotal = fixedFee > rate ? fixedFee : rate;
                        }
                    } else {
                        if (weight >= feeObj.setWeightFrom && weight <= feeObj.setWeightTo) {
                            let rate = weight * feeObj.fee;
                            let fixedFee = feeObj.fixedFee;
                            actualWeightTotal = fixedFee > rate ? fixedFee : rate;
                        }
                    }
                }

                actualWeightTotal > volumetricWeightTotal ? setTotalForwarderFee(actualWeightTotal) : setTotalForwarderFee(volumetricWeightTotal);
            } else {
                if (theCountry === feeObj.country_name && feeObj.weightType === "Actual Weight") {
                    if (!feeObj.setWeightTo) {
                        if (weight > feeObj.setWeightFrom) {
                            let rate = weight * feeObj.fee;
                            let fixedFee = feeObj.fixedFee;
                            actualWeightTotal = fixedFee > rate ? fixedFee : rate;
                        }
                    } else {
                        if (weight >= feeObj.setWeightFrom && weight <= feeObj.setWeightTo) {
                            let rate = weight * feeObj.fee;
                            let fixedFee = feeObj.fixedFee;
                            actualWeightTotal = fixedFee > rate ? fixedFee : rate;
                        }
                    }
                }
                setTotalForwarderFee(actualWeightTotal);
            }
        });

        serviceChargeData.forEach((data) => {
            if (!data.amountTo) {
                if (theSubTotal >= data.amountFrom) {
                    const percentage = data.commission / 100;
                    let rate = theSubTotal * percentage;
                    let fixedFee = data.fixedFee;

                    rate *= 100;
                    rate = Math.floor(rate);
                    rate /= 100;
                    const ans = rate > fixedFee ? rate.toFixed(2) : fixedFee.toFixed(2);
                    setTotalServiceFee(parseFloat(ans));
                }
            } else if (theSubTotal >= data.amountFrom && theSubTotal <= data.amountTo) {
                const percentage = data.commission / 100;
                let rate = theSubTotal * percentage;
                let fixedFee = data.fixedFee;

                rate *= 100;
                rate = Math.floor(rate);
                rate /= 100;
                const ans = rate > fixedFee ? rate.toFixed(2) : fixedFee.toFixed(2);
                setTotalServiceFee(parseFloat(ans));
            }
        });

        pickupLocations.forEach((data) => {
            if (data.state === values.prefferedLocation?.state) {
                setPickupLocationFee(parseInt(data.cost));
            }
        });

        vatData.forEach((data) => {
            if (data.name === "VAT") {
                const percentage = data.percentage / 100;
                setVatRate(totalServiceFee * percentage);
            }
        });

        supCountries.forEach((data) => {
            if (data.country === values.suplierCountry?.country) {
                let amount = values.quantity ? values.unitCost * values.quantity : values.unitCost;
                setSalesTax(parseFloat(amount * data.salesTax / 100).toFixed(2));
            }
        });
    }, [unitCost, quantity, handleUnitCostChange, handleQuantityChange, forwarderFees, selectPrefferedLocation, selectSuplierCountry]);

    const UpdateUserSchema = Yup.object().shape({
        unitCost: Yup.number().required('Unit cost is required').min(1, "value should be greater than one"),
        quantity: Yup.number().required('Quantity is required').min(1, "quantity should be greater than one"),
        type: Yup.object().nullable().required('Type of item is required'),
        prefferedLocation: Yup.object().nullable().required('Preferred Location is required'),
        suplierCountry: Yup.object().nullable().required('Supplier Country is required'),
        itemName: Yup.string().required('Item Name is required'),
        supplierName: Yup.string().required('Supplier Name is required'),
        itemLink: Yup.string().required('Product Link is required'),
        // images: Yup.mixed().required('Image is required').required('Product image is required'),
        amount:Yup.number().required('Amount is required'),
        costofShipping:Yup.number().required('Cost of Shipping is required'),
        // images: Yup.mixed().required('Image is required').test('image', 'Image is required', value => {
        //     if (!value) return false; // Handle empty values
        //     return true
        // }),
        images: Yup.mixed()
        .required('Image is required')
        .test('image', 'Image is required', value => {
          // Custom test function to handle empty values
          if (!value) {
            return false; 
          }
          return true; 
        }),
        
    
    });

    
  function formatMoney(amount) {
    // Split the amount into integer and decimal parts
    const parts = amount.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
    // Add commas every three digits from the right
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Concatenate integer and decimal parts and return
    return integerWithCommas + decimalPart;
  }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            type: viewData ? top100Films?.find((option) => option?.type === viewData?.type_of_item) : null,
            itemName: viewData ? viewData?.item_name : "",
            supplierName: viewData ? viewData?.supplier_name : "",
            unitCost: viewData ? viewData?.unit_cost : 0,
            quantity: viewData ? viewData?.quantity : 0,
            amount: viewData ? viewData?.amount : null,
            costofShipping: viewData ? viewData?.cost_of_shipping : null,
            weight: viewData ? viewData?.weight : null,
            actualWeight: viewData ? viewData?.actual_weight : null,
            lInches: viewData ? viewData?.l_inches : null,
            bInches: viewData ? viewData?.b_inches : null,
            hInches: viewData ? viewData?.h_inches : null,
            suplierCountry: viewData ? supCountries?.find((option) => option?.id == viewData?.supplier_country) : null,
            prefferedLocation: viewData ? pickupLocations?.find((option) => option.id == viewData.preffered_location) : null,
            subtotal: viewData ? viewData?.sub_total : null,
            ForwarderFee: viewData ? viewData?.forwarder_fee : 0,
            ServiceCharge: viewData ? viewData?.service_charge : 0,
            vat: viewData ? viewData?.vat : 0,
            customerNote: viewData ? viewData?.customer_note : null,
            ShopAmericaNotes: viewData ? viewData?.hop_america_note : null,
            pickupLocation: viewData ? viewData?.delivery_to_pickup_location : 0,
            total: viewData ? viewData?.total : null,
            userId: userId,
            status: id ? viewData?.status : productStatus,
            itemLink: link ? link : (viewData ? viewData?.item_link : ''),
            supplierCountryTax: viewData ? viewData?.supplier_country_tax : 0,
            images: viewData.image ? `${REST_API}${viewData.image}` : null,
        },
        validationSchema: UpdateUserSchema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            setIsSubmitting(true); // Disable the save and submit buttons

            let formData = new FormData();
            try {
                let theAmount = values.quantity ? values.unitCost * values.quantity : values.unitCost;
                let theSubTotal = values.costofShipping ? Number(theAmount) + Number(values.costofShipping) + Number(salesTax) : theAmount;
                const fullTotal = (subTotalValue + forwarderFeeValue + serviceFeeValue + pickupFeeValue + vatRateValue).toFixed(2);

                values.amount = parseFloat(theAmount).toFixed(2);
                values.ForwarderFee = totalForwarderFee;
                values.ServiceCharge = totalServiceFee;
                values.pickupLocation = pickupLocationFee;
                values.vat = vatRate.toFixed(2);
                values.total = fullTotal;
                values.subtotal = parseFloat(theSubTotal).toFixed(2);
                values.supplierCountryTax = salesTax;
                values.status = save === 1 ? "Awaiting Approval" : "Saved";
                values.weight = (values.lInches * values.bInches * values.hInches / 166)

                formData.append('type', values?.type.type);
                formData.append('itemName', values?.itemName);
                formData.append('supplierName', values?.supplierName);
                formData.append('unitCost', values?.unitCost);
                formData.append('quantity', values?.quantity);
                formData.append('amount', parseFloat(theAmount).toFixed(2));
                formData.append('costofShipping', values?.costofShipping);
                formData.append('weight', values?.weight);
                formData.append('actualWeight', values?.actualWeight);
                formData.append('lInches', values?.lInches);
                formData.append('bInches', values?.bInches);
                formData.append('hInches', values?.hInches);
                formData.append('suplierCountry', values.suplierCountry.id);
                formData.append('prefferedLocation', values?.prefferedLocation.id);
                formData.append('subtotal', parseFloat(theSubTotal).toFixed(2));
                formData.append('ForwarderFee', totalForwarderFee);
                formData.append('ServiceCharge', totalServiceFee);
                formData.append('vat', vatRate.toFixed(2));
                formData.append('customerNote', values.customerNote ? values.customerNote : "");
                formData.append('ShopAmericaNotes', "");
                formData.append('pickupLocation', pickupLocationFee);
                formData.append('total', fullTotal);
                formData.append('userId', values.userId);
                formData.append('status', save === 1 ? "Awaiting Approval" : "Saved");
                formData.append('itemLink', values.itemLink);
                formData.append('supplierCountryTax', salesTax);
                formData.append('images', values.images);
                formData.append('file', selectedFile);
                formData.append('save', save);

                if (id) {
                    
                    await axios.post(`${REST_API}webservice/admin/update-product-customer/${id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(res => {
                            if (res.data.status === 1) {
                                enqueueSnackbar(save ===1? "Product Submitted Successfully" :
                                "Product Saved Successfully", { variant: 'success' });
                                save ===2?
                                navigate(PATH_DASHBOARD.general.app)
                                :
                                navigate(PATH_DASHBOARD.orderHistory.root);
                                
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            enqueueSnackbar("Error occurred while submitting the product.", { variant: 'error' });
                        });
                } else {
                    const url = `${REST_API}webservice/admin/add-new-product`;
                    const response = await axios.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(res => {
                            if (res.data.status === 1) {
                                enqueueSnackbar(save ===1?"Product Submitted successfully" :
                                "Product Saved Successfully", { variant: 'success' });
                                save ===2?
                                navigate(PATH_DASHBOARD.general.app)
                                :
                                navigate(PATH_DASHBOARD.orderHistory.root);
                                

                            } else {
                                enqueueSnackbar(res.data.message, { variant: 'error' });
                            }
                        });
                }

                if (isMountedRef.current) {
                    setSubmitting(false);
                }
            } catch (error) {
                if (isMountedRef.current) {
                    setErrors({ afterSubmit: error.code });
                    setSubmitting(false);
                }
                enqueueSnackbar("Error occurred while submitting the product.", { variant: 'error' });
                console.log('error----------', error);
            } finally {
                setIsSubmitting(false); // Re-enable the buttons after submission
            }
        }
    });

    const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                setSelectedFile(file);
                setFieldValue('images', {
                    ...file,
                    preview: URL.createObjectURL(file)
                });
            }
        },
        [setFieldValue]
    );

    const handleSave = () => {
        handleSubmit();
    };

    const handleAgrementSubmit = () => {
        setSave(1);
        setAgreement(!agreement);
    };

    const handleAgree = async () => {
        try {
            await UpdateUserSchema.validate(formik.values, { abortEarly: false });
            setSave(1);
            setAgree(true);
            handleSubmit();
        } catch (error) {
            enqueueSnackbar("Please fill in all required fields.", { variant: 'error' });
            console.error('Validation Error:', error);
        }
    };

    const handleDisAgree = () => {
        setAgree(false);
        setAgreement(false);
    };

    const handleChangeAmount = (event) => {
        setAmount(values.quantity ? values.unitCost * values.quantity : values.unitCost);
    };

    let theAmount = values.unitCost * values.quantity;
    let theSubTotal = values.costofShipping ? Number(theAmount) + Number(values.costofShipping) + Number(salesTax) : Number(theAmount) + Number(salesTax);

    let subTotalValue = theSubTotal ? Number(theSubTotal) : 0;
    let forwarderFeeValue = totalForwarderFee ? Number(totalForwarderFee) : 0;
    let serviceFeeValue = totalServiceFee ? Number(totalServiceFee) : 0;
    let pickupFeeValue = pickupLocationFee ? Number(pickupLocationFee) : 0;
    let vatRateValue = vatRate ? Number(vatRate) : 0;

    const fullTotal = (subTotalValue + forwarderFeeValue + serviceFeeValue + pickupFeeValue + vatRateValue).toFixed(2);

    let theFinalTotal = 0;

    if (!isNaN(theSubTotal)) {
        theFinalTotal += Number(theSubTotal);
    }
    if (!isNaN(totalForwarderFee)) {
        theFinalTotal += Number(totalForwarderFee);
    }
    if (!isNaN(totalServiceFee)) {
        theFinalTotal += Number(totalServiceFee);
    }
    if (!isNaN(pickupLocationFee)) {
        theFinalTotal += Number(pickupLocationFee);
    }
    if (!isNaN(vatRate)) {
        theFinalTotal += Number(vatRate);
    }


    // function formatMoney(amount) {
    //     // Split the amount into integer and decimal parts
    //     const parts = amount.toString().split('.');
    //     const integerPart = parts[0];
    //     const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
      
    //     // Add commas every three digits from the right
    //     const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      
    //     // Concatenate integer and decimal parts and return
    //     return integerWithCommas + decimalPart;
    //   }

    function formatMoney(amount) {
        // Split the amount into integer and decimal parts
        const parts = amount.toString().split('.');
        const integerPart = parts[0];
        let decimalPart = parts.length > 1 ? parts[1] : '';
      
        // If decimalPart is empty or has only one digit, add a trailing zero
        if (decimalPart.length === 0) {
          decimalPart = '00';
        } else if (decimalPart.length === 1) {
          decimalPart = decimalPart + '0';
        } else {
          decimalPart = decimalPart.slice(0, 2); // Take only the first two digits
        }
      
        // Add commas every three digits from the right
        const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      
        // Concatenate integer and decimal parts and return
        return integerWithCommas + '.' + decimalPart;
      }

            if (isLoading) {
                return(
                    <>
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height:"100vh" }}>
                   <LoadingScreen/>
                  </Box>
            
                </>
                ) 
              }
  

    return (
        <FormikProvider value={formik}>
            <Modal
                open={agreement}
                handleClose={() => {
                    setAgreement(false);
                }}
                modalTitle={agree ? "Order Placed" : 'Service Agreements'}>
                <>
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <Typography color={agree ? "green" : ""}>{agree ? "Your order has been submitted and being reviewed you shall get an invoice shortly" : "Do you want to Submit? This process is irreversible."}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
                        {
                            agree ? "" :
                                (
                                    <>
                                        <Button variant='contained' sx={{ mr: 3,backgroundColor: '#be4c4c','&:hover': {
                                backgroundColor: '#be4c4c',
                              }, }} onClick={handleDisAgree}>No</Button>
                                        <Button variant='contained'  onClick={handleAgree}>Yes</Button>
                                    </>
                                )
                        }
                    </Box>
                </>
            </Modal>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3} sx={{ mt: "-50px" }}>
                    <Grid item xs={12} md={12}>
                        <Typography sx={{ display: { color: "#32b59f" } }}>Item Description</Typography>
                        
                        <Stack sx={{ p: 3 }}>
                            <Stack spacing={{ xs: 2, md: 3 }} sx={{ mt: 3 }}>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Item Link</LabelStyle>
                                        <TextField
                                            fullWidth
                                            placeholder='Paste the weblink to the product or shopping cart here. e.g. https://www.amazon.com/product....'
                                            sx={{ color: "blue" }}
                                            disabled={link ? true : false}
                                            {...getFieldProps('itemLink')}
                                            error={Boolean(touched.itemLink && errors.itemLink)}
                                            helperText={touched.itemLink && errors.itemLink}
                                        />
                                    </Box>
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'column' }} spacing={2}>
                                    <LabelStyle>Upload the picture of the product or shopping cart</LabelStyle>
                                    <UploadSingleFile
                                        maxSize={3145728}
                                        accept="image/*"
                                        file={values?.images}
                                        onDrop={handleDrop}
                                        error={Boolean(touched.images && errors.images)}
                                        helperText={touched.images && errors.images}

                                    />
                                    {touched.images && errors.images && (
                                        <FormHelperText error sx={{ px: 2 }}>
                                            {touched.images && errors.images}
                                        </FormHelperText>
                                    )}
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <>
                                        {
                                            id ?
                                                <>
                                                    <Box sx={{ width: "100%" }}>
                                                        <LabelStyle>Type of Item</LabelStyle>
                                                        <Autocomplete
                                                            select
                                                            fullWidth
                                                            options={top100Films}
                                                            getOptionLabel={(option) => option.type}
                                                            value={values.type || null}
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    setFieldValue('type', newValue);
                                                                } else {
                                                                    setFieldValue('type', '');
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Select type"
                                                                    error={Boolean(touched.type && errors.type)}
                                                                    helperText={touched.type && errors.type}
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                             <Box sx={{ width: "100%" }}>
                                                <LabelStyle>Supplier Country</LabelStyle>
                                                <Autocomplete
                                                    select
                                                    fullWidth
                                                    options={supCountries}
                                                    getOptionLabel={(option) => option.country}
                                                    value={values.suplierCountry || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setFieldValue('suplierCountry', newValue);
                                                            setSelectSuplierCountry(newValue)
                                                        } else {
                                                            setFieldValue('suplierCountry', '');
                                                            setSelectSuplierCountry('')
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Supplier Country"
                                                            error={Boolean(touched.suplierCountry && errors.suplierCountry)}
                                                            helperText={touched.suplierCountry && errors.suplierCountry}
                                                        />
                                                    )}
                                                />
                                            </Box>

                                            <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>Preferred Pickup location</LabelStyle>
                                                    <Autocomplete
                                                        select
                                                        fullWidth
                                                        options={pickupLocations}
                                                        getOptionLabel={(option) => option.state}
                                                        value={values.prefferedLocation || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setFieldValue('prefferedLocation', newValue);
                                                                setSelectPrefferedLocation(newValue);
                                                            } else {
                                                                setFieldValue('prefferedLocation', '');
                                                                setSelectPrefferedLocation('');
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder="preferred Pickup location"
                                                                error={Boolean(touched.prefferedLocation && errors.prefferedLocation)}
                                                                helperText={touched.prefferedLocation && errors.prefferedLocation}
                                                            />
                                                        )}
                                                    />
                                                </Box>

                                                </>
                                                :
                                                <>
                                                <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>Type of Item</LabelStyle>
                                                    <Autocomplete
                                                        fullWidth
                                                        options={top100Films}
                                                        getOptionLabel={(option) => option.type}
                                                        renderInput={(params) => <TextField {...params}
                                                            error={Boolean(touched.type && errors.type)}
                                                            helperText={touched.type && errors.type} />}
                                                        value={values.type}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue('type', newValue);
                                                            if (newValue && newValue.type === "Digital") {
                                                                setType(true);
                                                            } else {
                                                                setType(false);
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{ width: "100%" }}>
                                                <LabelStyle>Supplier Country</LabelStyle>
                                                <Autocomplete
                                                    fullWidth
                                                    options={supCountries}
                                                    getOptionLabel={(option) => option.country}
                                                    renderInput={(params) => <TextField {...params}
                                                        error={Boolean(touched.suplierCountry && errors.suplierCountry)}
                                                        helperText={touched.suplierCountry && errors.suplierCountry} />}
                                                    value={values.suplierCountry}
                                                    onChange={(event, newValue) => {
                                                        setFieldValue('suplierCountry', newValue);
                                                        setSelectSuplierCountry(newValue)
                                                    }}
                                                />
                                            </Box>

                                            <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>Preferred Pickup location</LabelStyle>
                                                    <Autocomplete
                                                        fullWidth
                                                        options={pickupLocations}
                                                        getOptionLabel={(option) => option.state}
                                                        renderInput={(params) => <TextField {...params}
                                                            error={Boolean(touched.prefferedLocation && errors.prefferedLocation)}
                                                            helperText={touched.prefferedLocation && errors.prefferedLocation} />}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue('prefferedLocation', newValue);
                                                            setSelectPrefferedLocation(newValue);
                                                        }}
                                                    />
                                                </Box>

                                                </>
                                                
                                        }
                                    </>
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Item Name</LabelStyle>
                                        <TextField fullWidth placeholder='Enter Item Name' defaultValue={id ? "Item Name" : ""}
                                            {...getFieldProps('itemName')}
                                            error={Boolean(touched.itemName && errors.itemName)}
                                            helperText={touched.itemName && errors.itemName} />
                                    </Box>
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Supplier</LabelStyle>
                                        <TextField fullWidth placeholder='Enter Supplier Name' defaultValue={id ? "Supplier" : ""}
                                            {...getFieldProps('supplierName')}
                                            error={Boolean(touched.supplierName && errors.supplierName)}
                                            helperText={touched.supplierName && errors.supplierName} />
                                    </Box>
                                </Stack>
                                {/* {
                                    id ?
                                        <>
                                            <Box sx={{ width: "100%" }}>
                                                <LabelStyle>Supplier Country</LabelStyle>
                                                <Autocomplete
                                                    select
                                                    fullWidth
                                                    options={supCountries}
                                                    getOptionLabel={(option) => option.country}
                                                    value={values.suplierCountry || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setFieldValue('suplierCountry', newValue);
                                                            setSelectSuplierCountry(newValue)
                                                        } else {
                                                            setFieldValue('suplierCountry', '');
                                                            setSelectSuplierCountry('')
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Supplier Country"
                                                            error={Boolean(touched.suplierCountry && errors.suplierCountry)}
                                                            helperText={touched.suplierCountry && errors.suplierCountry}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </>
                                        :
                                        <>
                                            <Box sx={{ width: "100%" }}>
                                                <LabelStyle>Supplier Country</LabelStyle>
                                                <Autocomplete
                                                    fullWidth
                                                    options={supCountries}
                                                    getOptionLabel={(option) => option.country}
                                                    renderInput={(params) => <TextField {...params}
                                                        error={Boolean(touched.suplierCountry && errors.suplierCountry)}
                                                        helperText={touched.suplierCountry && errors.suplierCountry} />}
                                                    value={values.suplierCountry}
                                                    onChange={(event, newValue) => {
                                                        setFieldValue('suplierCountry', newValue);
                                                        setSelectSuplierCountry(newValue)
                                                    }}
                                                />
                                            </Box>
                                        </>
                                } */}
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Unit cost</LabelStyle>
                                        <TextField placeholder='0.00' {...getFieldProps('unitCost')}
                                            InputProps={{
                                                type: 'number',
                                                inputProps: {
                                                    min: 0,
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.unitCost && errors.unitCost)}
                                            helperText={touched.unitCost && errors.unitCost}
                                            onChange={handleUnitCostChange}
                                            value={values.unitCost ? values.unitCost : unitCost} />
                                    </Box>
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Quantity</LabelStyle>
                                        <TextField   {...getFieldProps('quantity')}
                                            value={values?.quantity}
                                            InputProps={{
                                                type: 'number',
                                                inputProps: {
                                                    min: 1,
                                                }
                                            }}
                                            error={Boolean(touched.quantity && errors.quantity)}
                                            helperText={touched.quantity && errors.quantity}
                                            onChange={handleQuantityChange}
                                        />
                                    </Box>
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Amount</LabelStyle>
                                        <TextField
                                            fullWidth
                                            // type='number'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            {...getFieldProps('amount')}
                                            value={theAmount ? formatMoney(parseFloat(theAmount).toFixed(2)) : "0.00"}
                                            onChange={handleChangeAmount}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                        />
                                    </Box>
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Supplier Country's Tax</LabelStyle>
                                        <TextField sx={{ backgroundColor: "whitesmoke" }} fullWidth
                                            defaultValue={"$0.00"} placeholder="$0.00"
                                            {...getFieldProps('supplierCountryTax')}
                                            value={salesTax ? salesTax : "0.00"}
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.supplierCountryTax && errors.supplierCountryTax)}
                                            helperText={touched.supplierCountryTax && errors.supplierCountryTax} />
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                        {/* <LabelStyle fontSize={"small"}>Cost of shipping item from supplier to forwarder</LabelStyle> */}
                                        <LabelStyle style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>Cost of shipping item from supplier to forwarder</LabelStyle>

                                        <TextField placeholder='0.00' fullWidth
                                            {...getFieldProps('costofShipping')}
                                            InputProps={{
                                                type: 'number',
                                                inputProps: {
                                                    min: 0,
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.costofShipping && errors.costofShipping)}
                                            helperText={touched.costofShipping && errors.costofShipping} />
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                    <LabelStyle>Actual Weight of item (pounds)</LabelStyle>
                                    <TextField fullWidth defaultValue={id ? "Actual weight" : ""}
                                        {...getFieldProps('actualWeight')}
                                        InputProps={{
                                            type: 'number',
                                            inputProps: {
                                                min: 0,
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    lb
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.actualWeight && errors.actualWeight)}
                                        helperText={touched.actualWeight && errors.actualWeight} />
                                </Box>

                                </Stack>
                                {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Cost of Shipping item from supplier to forwarder</LabelStyle>
                                        <TextField placeholder='0.00' fullWidth
                                            {...getFieldProps('costofShipping')}
                                            InputProps={{
                                                type: 'number',
                                                inputProps: {
                                                    min: 0,
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.costofShipping && errors.costofShipping)}
                                            helperText={touched.costofShipping && errors.costofShipping} />
                                    </Box>
                                </Stack> */}

                                {/* <Box sx={{ width: "100%" }}>
                                    <LabelStyle>Actual Weight of item (pounds)</LabelStyle>
                                    <TextField fullWidth defaultValue={id ? "Actual weight" : ""}
                                        {...getFieldProps('actualWeight')}
                                        InputProps={{
                                            type: 'number',
                                            inputProps: {
                                                min: 0,
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    lb
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.actualWeight && errors.actualWeight)}
                                        helperText={touched.actualWeight && errors.actualWeight} />
                                </Box> */}
                                {
                                    viewData.type_of_item === "Digital" || type === true ? "" : (
                                        <>
                                            <Typography fontSize={"medium"}>Volumetric weight of item (pounds)</Typography>
                                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                                <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>L(inches)</LabelStyle>
                                                    <TextField fullWidth  {...getFieldProps('lInches')}
                                                        InputProps={{
                                                            type: 'number',
                                                            inputProps: {
                                                                min: 0,
                                                            }
                                                        }} onChange={handleLength}
                                                        value={values.lInches ? values.lInches : length}
                                                        error={Boolean(touched.lInches && errors.lInches)}
                                                        helperText={touched.lInches && errors.lInches} />
                                                </Box>
                                                <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>B(inches)</LabelStyle>
                                                    <TextField fullWidth  {...getFieldProps('bInches')}
                                                        InputProps={{
                                                            type: 'number',
                                                            inputProps: {
                                                                min: 0,
                                                            }
                                                        }} onChange={handleBredth}
                                                        value={values.bInches ? values.bInches : bredth}
                                                        error={Boolean(touched.bInches && errors.bInches)}
                                                        helperText={touched.bInches && errors.bInches} />
                                                </Box>
                                                <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>H(inches)</LabelStyle>
                                                    <TextField fullWidth  {...getFieldProps('hInches')}
                                                        InputProps={{
                                                            type: 'number',
                                                            inputProps: {
                                                                min: 0,
                                                            }
                                                        }} onChange={handleHeight}
                                                        value={values.hInches ? values.hInches : height}
                                                        error={Boolean(touched.hInches && errors.hInches)}
                                                        helperText={touched.hInches && errors.hInches} />
                                                </Box>

                                                <Box sx={{ width: "100%" }}>
                                                <LabelStyle style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>Volumetric Weight calculated by system</LabelStyle>
                                                    <TextField fullWidth  {...getFieldProps('weight')}
                                                        value={(values.lInches * values.bInches * values.hInches / 166).toFixed(2)}
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    lb
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        error={Boolean(touched.weight && errors.weight)}
                                                        helperText={touched.weight && errors.weight} />
                                                </Box>

                                            </Stack>
                                            {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                                <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>Volumetric Weight calculated by system</LabelStyle>
                                                    <TextField fullWidth  {...getFieldProps('weight')}
                                                        value={(values.lInches * values.bInches * values.hInches / 166).toFixed(2)}
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    lb
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        error={Boolean(touched.weight && errors.weight)}
                                                        helperText={touched.weight && errors.weight} />
                                                </Box>
                                            </Stack> */}
                                        </>
                                    )
                                }
                                {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                    {
                                        id ?
                                            <>
                                                <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>Preferred Pickup location</LabelStyle>
                                                    <Autocomplete
                                                        select
                                                        fullWidth
                                                        options={pickupLocations}
                                                        getOptionLabel={(option) => option.state}
                                                        value={values.prefferedLocation || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setFieldValue('prefferedLocation', newValue);
                                                                setSelectPrefferedLocation(newValue);
                                                            } else {
                                                                setFieldValue('prefferedLocation', '');
                                                                setSelectPrefferedLocation('');
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder="preferred Pickup location"
                                                                error={Boolean(touched.prefferedLocation && errors.prefferedLocation)}
                                                                helperText={touched.prefferedLocation && errors.prefferedLocation}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </>
                                            :
                                            <>
                                                <Box sx={{ width: "100%" }}>
                                                    <LabelStyle>Preferred Pickup location</LabelStyle>
                                                    <Autocomplete
                                                        fullWidth
                                                        options={pickupLocations}
                                                        getOptionLabel={(option) => option.state}
                                                        renderInput={(params) => <TextField {...params}
                                                            error={Boolean(touched.prefferedLocation && errors.prefferedLocation)}
                                                            helperText={touched.prefferedLocation && errors.prefferedLocation} />}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue('prefferedLocation', newValue);
                                                            setSelectPrefferedLocation(newValue);
                                                        }}
                                                    />
                                                </Box>
                                            </>
                                    }
                                </Stack> */}


                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} display={"flex"} justifyContent={"end"}>
                                    <TextField sx={{ backgroundColor: "whitesmoke" }} label="Sub Total" defaultValue={"$00"} placeholder='$50' {...getFieldProps('subtotal')}
                                        value={theSubTotal ? formatMoney(parseFloat(theSubTotal).toFixed(2)) : "0.00"}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.subtotal && errors.subtotal)}
                                        helperText={touched.subtotal && errors.subtotal} />
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} >
                                    <Typography sx={{ width: '50%', color: "#32b59f", display: { xs: "none", md: "flex" } }}>Shipping Charges</Typography>
                                    <Typography sx={{ width: '50%', color: "#32b59f", display: { xs: "none", md: "flex" } }} fullWidth>Service Charges</Typography>
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} >
                                    <Typography sx={{ width: '50%', color: "#32b59f", display: { xs: "flex", md: "none" } }}
                                        fullWidth>Shipping Charges</Typography>
                                    <TextField sx={{ backgroundColor: "whitesmoke" }} fullWidth label="Forwarder Fee"
                                        defaultValue={"$0.00"} placeholder="$0.00" disabled
                                        {...getFieldProps('ForwarderFee')}
                                        value={totalForwarderFee ? totalForwarderFee.toFixed(2) : "0.00"}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.ForwarderFee && errors.ForwarderFee)}
                                        helperText={touched.ForwarderFee && errors.ForwarderFee} />
                                    <TextField sx={{ backgroundColor: "whitesmoke", display: { xs: "none", md: "flex" } }} fullWidth
                                        label="Service Charge" defaultValue={"$0.00"} placeholder="$0.00" disabled
                                        {...getFieldProps('ServiceCharge')} value={totalServiceFee}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.ServiceCharge && errors.ServiceCharge)}
                                        helperText={touched.ServiceCharge && errors.ServiceCharge} />
                                    <TextField sx={{ backgroundColor: "whitesmoke", display: { xs: "flex", md: "none" } }} fullWidth label="Delivery to pickup Location"
                                        defaultValue={"$0.00"} placeholder="$0.00" disabled
                                        {...getFieldProps('pickupLocation')} value={pickupLocationFee}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.pickupLocation && errors.pickupLocation)}
                                        helperText={touched.pickupLocation && errors.pickupLocation} />
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} >
                                    <Typography sx={{ width: '50%', color: "#32b59f", display: { xs: "flex", md: "none" } }}
                                        fullWidth>Service Charges</Typography>
                                    <TextField sx={{ backgroundColor: "whitesmoke", display: { xs: "none", md: "flex" } }} fullWidth label="Delivery to pickup Location"
                                        defaultValue={"$0.00"} placeholder="$0.00" disabled
                                        {...getFieldProps('pickupLocation')} value={pickupLocationFee}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.pickupLocation && errors.pickupLocation)}
                                        helperText={touched.pickupLocation && errors.pickupLocation} />
                                    <TextField sx={{ backgroundColor: "whitesmoke", display: { xs: "flex", md: "none" } }}
                                        fullWidth label="Service Charge" disabled
                                        defaultValue={"$0.00"} placeholder="$0.00" {...getFieldProps('ServiceCharge')}
                                        value={totalServiceFee}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.ServiceCharge && errors.ServiceCharge)}
                                        helperText={touched.ServiceCharge && errors.ServiceCharge} />
                                    <TextField sx={{ backgroundColor: "whitesmoke" }} fullWidth label="VAT on Service Charge"
                                        defaultValue={"$0.00"} placeholder="$0.00" {...getFieldProps('vat')} disabled
                                        value={vatRate ? vatRate.toFixed(2) : "0.00"}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.vat && errors.vat)}
                                        helperText={touched.vat && errors.vat} />
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} >
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Customer Note</LabelStyle>
                                        <TextField fullWidth placeholder='Enter Notes'
                                            {...getFieldProps('customerNote')}
                                            error={Boolean(touched.customerNote && errors.customerNote)}
                                            helperText={touched.customerNote && errors.customerNote} />
                                    </Box>
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} >
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>ShopAmerica’s Note</LabelStyle>
                                        <TextField sx={{ backgroundColor: "whitesmoke" }} fullWidth
                                            disabled placeholder='Note' {...getFieldProps('ShopAmericaNotes')}
                                            error={Boolean(touched.ShopAmericaNotes && errors.ShopAmericaNotes)}
                                            helperText={touched.ShopAmericaNotes && errors.ShopAmericaNotes} />
                                    </Box>
                                </Stack>
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} >
                                    <Box sx={{ width: "100%" }}>
                                        <LabelStyle>Invoice</LabelStyle>
                                        <TextField sx={{ backgroundColor: "whitesmoke" }} fullWidth disabled 
                                        defaultValue={"Your Invoice will be available here for view/download when Payment is approved."}
                                         placeholder='$50' {...getFieldProps('Invoice')} />
                                    </Box>
                                </Stack>
                            </Stack>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <TextField sx={{ backgroundColor: "whitesmoke" }} label="Total"
                                    defaultValue={"00"} placeholder='50' {...getFieldProps('total')}
                                    value={fullTotal ? formatMoney(fullTotal) : "0.00"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    error={Boolean(touched.total && errors.total)}
                                    helperText={touched.total && errors.total}
                                />
                            </Box>
                            {
                                retrive ?
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mt: 5 }}>
                                        <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.root}>
                                            <Button variant='contained' sx={{ mr: 3, backgroundColor: "#32b59f",boxShadow:"none" }}>Close</Button>
                                        </Link>
                                        <LoadingButton type="submit" sx={{ backgroundColor: "#32b59f", mr: 3,boxShadow:"none" }} variant="contained" loading={isSubmitting}>
                                            Save
                                        </LoadingButton>
                                        <Button variant='contained' sx={{ backgroundColor: "#32b59f" ,boxShadow:"none"}} onClick={handleAgrementSubmit}>Submit</Button>
                                    </Box>
                                    :
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mt: 2 }}>
                                        <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.orderHistory.root}>
                                            <Button variant='contained' sx={{ mr: 3, backgroundColor: "#32b59f" }}>Close</Button>
                                        </Link>
                                        <Button variant='contained' sx={{ backgroundColor: "#32b59f", mr: 3 }}>I have Made payment</Button>
                                    </Box>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}