// import faker from 'faker';
// import PropTypes from 'prop-types';
// // material
// import { Card, Typography, CardHeader, CardContent, Stack, Box } from '@material-ui/core';
// import { Timeline, TimelineItem, TimelineContent, TimelineConnector, TimelineSeparator } from '@material-ui/lab';
// // utils
// import { fDateTime } from '../../../../utils/formatTime';
// //
// import { MIconButton, MTimelineDot } from '../../../@material-extend';
// import { Check } from '@material-ui/icons';
// import { useEffect, useState } from 'react';
// import { REST_API } from 'src/DefaultValues';
// import axios from 'axios';
// // ----------------------------------------------------------------------

// const TIMELINESs = [
//   {
//     title: 'Awaiting Approval',
//     time: faker.date.past(),
//     type: 'order1'
//   },
//   {
//     title: 'Approved for Payment',
//     time: faker.date.past(),
//     type: 'order2'
//   },
//   {
//     title: 'Payment Made',
//     time: faker.date.past(),
//     type: 'order3'
//   },
//   {
//     title: 'Order Placed',
//     time: faker.date.past(),
//     type: 'order4'
//   },
//   {
//     title: 'Ready for Shipment',
//     time: faker.date.past(),
//     type: 'order5'
//   }, {
//     title: 'Undergoing Clearing',
//     time: faker.date.past(),
//     type: 'order5'
//   }, {
//     title: 'Ready for Pickup',
//     time: faker.date.past(),
//     type: 'order5'
//   }, {
//     title: 'Marked for Demurrage',
//     time: faker.date.past(),
//     type: 'order5'
//   }, {
//     title: 'Marked for Auction',
//     time: faker.date.past(),
//     type: 'order5'
//   }
// ];

// // ----------------------------------------------------------------------

// // OrderItem.propTypes = {
// //   item: PropTypes.object,
// //   isLast: PropTypes.bool
// // };

// // function OrderItem({ item, isLast }) {
// //   const { type, title, time } = item;
// //   return (
// //     <TimelineItem sx={{minHeight:"113px"}}>
// //       <TimelineSeparator>
// //         <MTimelineDot
// //           color={
// //             (type === 'order1' && 'primary') ||
// //             (type === 'order2' && 'success') ||
// //             (type === 'order3' && 'info') ||
// //             (type === 'order4' && 'warning') ||
// //             'error'
// //           }
// //         />
// //         {isLast ? null : <TimelineConnector />}
// //       </TimelineSeparator>
// //       <TimelineContent>
// //         <Typography variant="subtitle2">{title}</Typography>
// //         <Typography variant="caption" sx={{ color: 'text.secondary' }}>
// //           {time}
// //         </Typography>
// //       </TimelineContent>
// //     </TimelineItem>
// //   );
// // }

// // export default function ProductTimeline({view,date}) {
// //   console.log(date,"iiiiiiiiiii");
// //   const TIMELINES = [

// //     {
// //       title: 'Awaiting Approval',
// //       time: faker.date.past(),
// //       type: 'order1'
// //     },
// //   ] 
// //   console.log(date);
// //   return (
// //     <Card
// //       sx={{
// //         '& .MuiTimelineItem-missingOppositeContent:before': {
// //           display: 'none',
// //         },
// //         width:"100%",
// //         display:"flex",
// //         alignItems:"center",
// //         flexDirection:"column"
// //       }}
// //     >
// //       <CardHeader title="Order Timeline" />
// //       <CardContent>
// //         {view ?
       
// //         <Timeline>
// //           {TIMELINES.map((item, index) => (
// //             <OrderItem key={item.title} item={item} isLast={index === TIMELINES.length - 1} />
// //           ))}
// //         </Timeline>
// //          :
// //         //  <Timeline>
// //         //   {TIMELINESs.map((item, index) => (
// //         //     <OrderItem key={item.title} item={item} isLast={index === TIMELINES.length - 1} />
// //         //   ))}
// //         // </Timeline>  
// //         ""
// //          }
// //       </CardContent>
// //     </Card>
// //   );
// // }


// OrderItem.propTypes = {
//   item: PropTypes.object,
//   isLast: PropTypes.bool
// };

// function OrderItem({ item, isLast }) {
//   const { type, title, time,checked } = item;
//   // Parse the date string in dd-mm-yyyy format
//   const [day, month, year] = time.split('-').map(Number);
//   const formattedDate = new Date(year, month - 1, day); // month is zero-based in Date object

//   // Format the date into dd-mm-yyyy format
//   // const formattedDateString = formattedDate.toLocaleDateString('en-GB');
//   let formattedDateString = ''

//    // Check if time is provided and is a valid date format
//    if (time && /^\d{2}-\d{2}-\d{4}$/.test(time)) {
//     // Parse the date string in dd-mm-yyyy format
//     const [day, month, year] = time.split('-').map(Number);
//     const formattedDate = new Date(year, month - 1, day); // month is zero-based in Date object

//     // Format the date into dd-mm-yyyy format
//     formattedDateString = formattedDate.toLocaleDateString('en-GB');
//   }

//   return (
//     <TimelineItem sx={{minHeight:"113px"}}>
//       <TimelineSeparator>
//         <MTimelineDot
//           color={
//             (type === 'order1' && 'primary') ||
//             (type === 'order2' && 'success') ||
//             (type === 'order3' && 'info') ||
//             (type === 'order4' && 'warning') ||
//             'error'
//           }
//         />
//         {isLast ? null : <TimelineConnector />}
//       </TimelineSeparator>
//       <TimelineContent>
//         <Stack direction={'row'} spacing={1.4}>
//           <Typography variant="subtitle2">{title}</Typography>
//           {
//             checked ? 
//           <Check sx={{color:'success.dark',fontSize:'1.2rem'}}/>
//           : ""
//           }
//         </Stack>
//         <Typography variant="caption" sx={{ color: 'text.secondary' }}>
//           {time}
//         </Typography>
//       </TimelineContent>
//     </TimelineItem>
//   );
// }

// export default function ProductTimeline({ view, date ,status,productId}) {
//   // console.log(date, "iiiiiiiiiii");
//   console.log("productId",productId);
//   const [timelineData,setTimelineData] = useState({})

 

   


//   useEffect(()=> {
//   axios.get(`${REST_API}webservice/admin/get-products-timeline/${productId}`)
//   .then(res => {
//     console.log("productTimeline",res.data.result[0])
//     setTimelineData(res.data.result[0])
//   }).catch(err => console.log("err",err))
//   },[productId])


//   const TIMELINES = [
//     {
//       title: status==="Disapprove"? "Disapproved":`Awaiting Approval`,
//       checked:status==="Disapprove"? "Disapproved":'Awaiting Approval',
//       time: date,
//       type: 'order4'
//     },
//     {
//       title:  "Approved for Payment",
//       checked: status!=="Awaiting Approval" && status !=="Disapprove"? "Approved for Payment":"",
//       time: status!=="Awaiting Approval" && status !=="Disapprove"? date:'',
//       type: 'order3'
//     },
//     {
//       title:"Payment Made",
//       checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Disapprove"? "Payment Made" : '',
//       time: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Disapprove"? date : '',
//       type: 'order2'
//     },
//     {
//       title: "Ready for Shipment",
//       checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" && status !=="Disapprove"? "Ready for Shipment" : '',
//       time: status !== "Awaiting Approval" && status !== "Approved for Payment"  && status !=="Payment Made" && status !=="Disapprove"? date : '',
//       type: 'order3'
//     },
//     {
//       title: "Undergoing Clearing",
//       checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//                  && status !=="Ready for Shipment" && status !=="Disapprove"? "Undergoing Clearing" : '',
//       time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//               && status !=="Ready for Shipment" && status !=="Disapprove"? date : '',
//       type: 'order1'
//     },

//     {
//       title: "Ready for Pickup",
//       checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//                  && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && 
//                   status !=="Undergoing Clearing"  && status !=="Disapprove"? "Ready for Pickup" : '',
//       time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//               && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing"  && status !=="Disapprove"? date : '',
//       type: 'order1'
//     },

//     {
//       title: "Order Placed",
//       checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//                  && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
//                  && status !=="Ready for Pickup" && status !=="Disapprove"       ? "Order Placed" : '',
//       time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//               && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
//               && status !=="Ready for Pickup" && status !=="Disapprove"? date : '',
//       type: 'order1'
//     },

//     {
//       title: "Marked for Demurrage",
//       checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//                  && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
//                  && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Disapprove"     
//                      ? "Marked for Demurrage" : '',
//       time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//               && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
//               && status !=="Ready for Pickup" && status !=="Order Placed" && status !=="Disapprove"? date : '',
//       type: 'order1'
//     },

//     {
//       title: "Marked for Auction",
//       checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//                  && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
//                  && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Marked for Demurrage"
//                  && status !=="Disapprove"   ? "Marked for Auction" : '',
//       time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
//               && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
//               && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Marked for Demurrage" && status !=="Disapprove"? date : '',
//       type: 'order1'
//     },

//   ]

//   const statusDetails = [
//     { Pickup:"Awaiting Approval"},
//     { Pickup:"Approved for Payment"},
//     { Pickup:"Payment Made"},
//     { Pickup:"Ready for Shipment"},
//     { Pickup:"Undergoing Clearing"},
//     { Pickup:"Ready for Pickup"},
//     { Pickup:"Order Placed"},
//     { Pickup:"Marked for Demurrage"},
//     { Pickup:"Marked for Auction"},
//     { Pickup:"Disapprove"},
//   ];

//   console.log(date);
  
//   return (
//     <Card
//       sx={{
//         '& .MuiTimelineItem-missingOppositeContent:before': {
//           display: 'none',
//         },
//         width:"100%",
//         display:"flex",
//         alignItems:"center",
//         flexDirection:"column"
//       }}
//     >
//       <CardHeader title="Order Timeline" />
//       <CardContent>
//         {view ?
//           <Timeline>
//             {TIMELINES.map((item, index) => (
//               <OrderItem key={item.title} item={item}  isLast={index === TIMELINES.length - 1} />
//             ))}
//           </Timeline>
//           : ""
//         }
//       </CardContent>
//     </Card>
//   );
// }














import faker from 'faker';
import PropTypes from 'prop-types';
// material
import { Card, Typography, CardHeader, CardContent, Stack, Box, Button } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineContent, TimelineConnector, TimelineSeparator } from '@material-ui/lab';
// utils
import { fDateTime } from '../../../../utils/formatTime';
//
import { MIconButton, MTimelineDot } from '../../../@material-extend';
import { Check } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { REST_API } from 'src/DefaultValues';
import axios from 'axios';
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------



OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

function OrderItem({ item, isLast }) {
  const { type, title, time, checked } = item;

  // Check if time is provided and is a valid date format
  let formattedDateString = '';
  if (time && /^\d{2}-\d{2}-\d{4}$/.test(time)) {
    const [day, month, year] = time.split('-').map(Number);
    const formattedDate = new Date(year, month - 1, day);
    formattedDateString = formattedDate.toLocaleDateString('en-GB');
  }

  return (
    <TimelineItem sx={{minHeight:"113px"}}>
      <TimelineSeparator>
        <MTimelineDot
          color={
            (type === 'order1' && 'primary') ||
            (type === 'order2' && 'success') ||
            (type === 'order3' && 'info') ||
            (type === 'order4' && 'warning') ||
            'error'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Stack direction={'row'} spacing={1.4}>
          <Typography variant="subtitle2">{title}</Typography>
          {checked ? <Check sx={{color:'success.dark',fontSize:'1.2rem'}}/> : ""}
        </Stack>
        {time && (
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {formattedDateString}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}


export default function ProductTimeline({ view, date ,status,productId,countryId}) {
  // console.log(date, "iiiiiiiiiii");
  console.log("productId",productId);
  const [timelineData,setTimelineData] = useState({})
  const [edd,setEdd] = useState()

 

   


  useEffect(()=> {
  axios.get(`${REST_API}webservice/admin/get-products-timeline/${productId}`)
  .then(res => {
    console.log("productTimeline",res.data.result[0])
    setTimelineData(res.data.result[0])
  }).catch(err => console.log("err",err))

  axios.get(`${REST_API}webservice/admin/get-country/${countryId}`)
  .then(res =>{
    console.log("countryDetails",res.data.result)
    setEdd(res.data.result.edd)
  }).catch(err => console.log("err",err))
  },[productId,countryId])


  // const TIMELINES = [
  //   {
  //     title: status==="Disapprove"? "Disapproved":`Awaiting Approval`,
  //     checked:status==="Disapprove"? "Disapproved":'Awaiting Approval',
  //     time: status==="Disapprove"?  timelineData.disapprove : timelineData.awaiting_approval,
  //     type: 'order4'
  //   },
  //   {
  //     title:  "Approved for Payment",
  //     checked: status!=="Awaiting Approval" && status !=="Disapprove"? "Approved for Payment":"",
  //     time: status!=="Awaiting Approval" && status !=="Disapprove"? timelineData.approved_for_payment:'',
  //     type: 'order3'
  //   },
  //   {
  //     title:"Payment Made",
  //     checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Disapprove"? "Payment Made" : '',
  //     time: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Disapprove"? timelineData.payment_made : '',
  //     type: 'order2'
  //   },
  //   {
  //     title: "Ready for Shipment",
  //     checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" && status !=="Disapprove"? "Ready for Shipment" : '',
  //     time: status !== "Awaiting Approval" && status !== "Approved for Payment"  && status !=="Payment Made" && status !=="Disapprove"? timelineData.ready_for_shipment : '',
  //     type: 'order3'
  //   },
  //   {
  //     title: "Undergoing Clearing",
  //     checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //                && status !=="Ready for Shipment" && status !=="Disapprove"? "Undergoing Clearing" : '',
  //     time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //             && status !=="Ready for Shipment" && status !=="Disapprove"? timelineData.undergoing_clearing : '',
  //     type: 'order1'
  //   },

  //   {
  //     title: "Ready for Pickup",
  //     checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //                && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && 
  //                 status !=="Undergoing Clearing"  && status !=="Disapprove"? "Ready for Pickup" : '',
  //     time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //             && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing"  && status !=="Disapprove"? timelineData.ready_for_pickup : '',
  //     type: 'order1'
  //   },

  //   {
  //     title: "Order Placed",
  //     checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //                && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
  //                && status !=="Ready for Pickup" && status !=="Disapprove"       ? "Order Placed" : '',
  //     time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //             && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
  //             && status !=="Ready for Pickup" && status !=="Disapprove"? timelineData.order_placed : '',
  //     type: 'order1'
  //   },

  //   {
  //     title: "Marked for Demurrage",
  //     checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //                && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
  //                && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Disapprove"     
  //                    ? "Marked for Demurrage" : '',
  //     time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //             && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
  //             && status !=="Ready for Pickup" && status !=="Order Placed" && status !=="Disapprove"? timelineData.marked_for_demurrage : '',
  //     type: 'order1'
  //   },

  //   {
  //     title: "Marked for Auction",
  //     checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //                && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
  //                && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Marked for Demurrage"
  //                && status !=="Disapprove"   ? "Marked for Auction" : '',
  //     time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
  //             && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
  //             && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Marked for Demurrage" && status !=="Disapprove"? timelineData.	marked_for_auctioning : '',
  //     type: 'order1'
  //   },

  // ]

  const TIMELINES = [
    {
      title: status==="Disapprove"? "Disapproved":`Awaiting Approval`,
      checked:status==="Disapprove"? "Disapproved":'Awaiting Approval',
      time: status==="Disapprove"?  timelineData.disapprove : timelineData?.awaiting_approval,
      type: 'order4'
    },
    {
      title:  "Approved for Payment",
      checked: status!=="Awaiting Approval" && status !=="Disapprove"? "Approved for Payment":"",
      time: status!=="Awaiting Approval" && status !=="Disapprove"? timelineData?.approved_for_payment:'',
      type: 'order3'
    },
    {
      title:"Payment Made",
      checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Disapprove"? "Payment Made" : '',
      time: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Disapprove"? timelineData?.payment_made : '',
      type: 'order2'
    },
    {
      title: "Order Placed",
      checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
                 && status !=="Disapprove"    ? "Order Placed" : '',
      time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
             && status !=="Disapprove"    ? timelineData?.order_placed : '',
      type: 'order1'
    },

    {
      title: "Ready for Shipment",
      checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" && status !=="Order Placed" && status !=="Disapprove"? "Ready for Shipment" : '',
      time: status !== "Awaiting Approval" && status !== "Approved for Payment"  && status !=="Payment Made" && status !=="Order Placed" && status !=="Disapprove"? timelineData?.ready_for_shipment : '',
      type: 'order3'
    },
    {
      title: "Undergoing Clearing",
      checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" && status !=="Order Placed"
                 && status !=="Ready for Shipment" && status !=="Disapprove"? "Undergoing Clearing" : '',
      time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made"  && status !=="Order Placed"
              && status !=="Ready for Shipment" && status !=="Disapprove"? timelineData?.undergoing_clearing : '',
      type: 'order1'
    },

    {
      title: "Ready for Pickup",
      checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made"  && status !=="Order Placed"
                 && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && 
                  status !=="Undergoing Clearing"  && status !=="Disapprove"? "Ready for Pickup" : '',
      time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made"  && status !=="Order Placed"
              && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing"  && status !=="Disapprove"? timelineData?.ready_for_pickup : '',
      type: 'order1'
    },
    {
      title: "Marked for Demurrage",
      checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
                 && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
                 && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Disapprove"     
                     ? "Marked for Demurrage" : '',
      time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
              && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
              && status !=="Ready for Pickup" && status !=="Order Placed" && status !=="Disapprove"? timelineData?.marked_for_demurrage : '',
      type: 'order1'
    },

    {
      title: "Marked for Auction",
      checked: status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
                 && status !=="Ready for Shipment"   && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
                 && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Marked for Demurrage"
                 && status !=="Disapprove"   ? "Marked for Auction" : '',
      time:  status !== "Awaiting Approval" && status !== "Approved for Payment" && status !=="Payment Made" 
              && status !=="Ready for Shipment" && status !=="Undergoing clearing" && status !=="Undergoing Clearing" 
              && status !=="Ready for Pickup" && status !=="Order Placed"  && status !=="Marked for Demurrage" && status !=="Disapprove"? timelineData?.marked_for_auctioning : '',
      type: 'order1'
    },

  ]


  const statusDetails = [
    { Pickup:"Awaiting Approval"},
    { Pickup:"Approved for Payment"},
    { Pickup:"Payment Made"},
    { Pickup:"Ready for Shipment"},
    { Pickup:"Undergoing Clearing"},
    { Pickup:"Ready for Pickup"},
    { Pickup:"Order Placed"},
    { Pickup:"Marked for Demurrage"},
    { Pickup:"Marked for Auction"},
    { Pickup:"Disapprove"},
  ];

  console.log(date);


 // Function to parse date string into Date object
 const parseDate = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split('-');
  return new Date(year, month - 1, day); // month - 1 because month is 0-indexed in Date constructor
};

// Function to add days to a date
const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const formatDate = (date) => {
  if (!date) return '';
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}-${month}-${year}`;
};

// Calculate payment made date and EDD date
let paymentMadeDate = parseDate(timelineData?.payment_made);
let eddDate = paymentMadeDate ? addDays(paymentMadeDate, edd) : null;
let formattedEddDate = eddDate ? formatDate(eddDate) : '';

  
  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none',
        },
        width:"100%",
        display:"flex",
        alignItems:"center",
        flexDirection:"column"
      }}
    >
      <CardHeader title="Order Timeline" />
      <CardContent> 
        <Button variant='outlined' sx={{cursor:'none'}}>
          <Stack direction={'column'}>
            <Typography fontSize={'small'}>Expected Date of Delivery (EDD) </Typography>
            {
             !formattedEddDate?
              <Typography align='center' sx={{mt:2}}>{edd} days from the date of Payment</Typography> 
              :
              <Typography align='center' sx={{mt:2}}>  {formattedEddDate}</Typography> 
            }
          </Stack>
        </Button>
      </CardContent>
      <CardContent>
        {view ?
          <Timeline>
            {TIMELINES.map((item, index) => (
              <OrderItem key={item.title} item={item}  isLast={index === TIMELINES.length - 1} />
            ))}
          </Timeline>
          : ""
        }
      </CardContent>
    </Card>
  );
}
